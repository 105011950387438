.main-layout{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction:row;
}
.mobile-warning {
  color: red;
  font-size: 20px;
  text-align: center;
  margin-top: 50px;
}

.image-responsive {
  width: 90%;
  max-width: 650px;
  margin: 0 auto;
  display: block;
}

.main-layout-section{
  padding: 10px;
  overflow-y: auto;
  flex-grow: 1;
}