.wait-page{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 100px;
    gap: 50px;
    font-size: 18px;
    font-weight: bold;
}

.flex-center-row{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-direction: row;
}

.flex-center-column{
    display: flex;
    align-items: center;
    gap: 20px;
    flex-direction: column;
}

button{
    font-weight: bold;
    font-size: 16px;
    padding: 7px;
    cursor: pointer;
}