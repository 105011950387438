.presentation-img{
  width: 60%;
}

.presentation-wrapper{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.presentation-layout{
  padding-top: 50px;
  display: flex;
  flex-direction: row;

  width: 80vw;
}

.presentation-column{
  flex: 1;
}

.table-container {
  width: 80%;
  margin: 20px auto;
  border-collapse: collapse;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 15px;
  text-align: left;
}

.table thead {
  background-color: #f0f0f0;
}

.table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.table th {
  background-color: #d3d3d3;
  color: #000;
  font-weight: bold;
}

.table td {
  color: #000;
}

.table .col-1 {
  width: 33%;
}

.table .col-2 {
  width: 66%;
}
