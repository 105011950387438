.main-menu{
  width: 300px;
  border-right: solid grey 1px;
  -webkit-box-shadow: 5px 0px 28px -7px rgba(0,0,0,0.24); 
  box-shadow: 5px 0px 28px -7px rgba(0,0,0,0.24);
  height: 100vh;
  overflow-y: auto;
}

.main-menu-fold{
  width: 0px;
  height: 100vh;
  overflow: visible;
  z-index: 2;
}

.main-menu-section-title{
  font-size: 18px;
  display: inline-block;
  transition: transform 0.3s ease;
  cursor: pointer;
}

.main-menu-section-title:hover {
  transform: scale(1.1);
}

.titles-container{
  padding: 20px;
  display: flex;
  flex-direction  : column;
  align-items: center;
  gap: 20px;
}

.bold{
  font-weight: bold;
}

.text {
  white-space: normal; /* Permet au texte de se couper automatiquement */
  word-wrap: break-word; /* Assure que le texte est coupé si nécessaire */
  overflow-wrap: break-word; /* Assure que le texte est coupé si nécessaire */
}

.fold-arrow{
  position: absolute;
  top:48vh;
  left: 300px;
  transition: transform 0.3s ease;
  cursor: pointer;
  z-index: 3;
}

.fold-arrow:hover {
  transform: scale(1.1);
}

.unfold-arrow{
  position: absolute;
  top:48vh;
  left: 10px;
  transition: transform 0.3s ease;
  cursor: pointer;
  z-index: 3;
  transform: rotate(180deg);
}

.unfold-arrow:hover {
  transform: scale(1.1);
}